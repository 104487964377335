@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  font-family: "Poppins", sans-serif;
}


body {
  margin: 0px !important;
  padding: 0;
  font-family: "Montserrat", sans-serif;
}
.logo{
  color: #853096;
  display: flex;
  align-items: center;
}
.logo p{
  color: white;
  font-size: 23px;
}
.slash{
  font-size: 22px;

}
.symbols{
  font-size: 45px;

}
/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 80px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #222a35;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #575c66;
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #626970;
}

::-webkit-backdrop-filter {
  blur: 3px saturate(106%);
}